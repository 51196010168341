import React, { useState, useEffect, useRef } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Chip,
  Dialog,
  DialogContent,
} from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VimeoPlayer from './VimeoPlayer';
import { gsap } from 'gsap';

const PersonDetails = ({ person, shouldShrink }) => {
  const { name, birthDate, deathDate, residence, imageUrl, videoUrl } = person;

  // Define state variables for the lightbox
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxVideoUrl, setLightboxVideoUrl] = useState('');

  const cardRef = useRef(null); // Ref for the card element

  useEffect(() => {
    // if (shouldShrink > 1) {
    //   gsap.to(cardRef.current, { height: 0, padding: 0, duration: .25, ease: "power2" });
    // } else {
    //   gsap.to(cardRef.current, { height: 300, padding: 40, duration: .25, ease: "power2"  });
    // } 
  }, [shouldShrink]);

  // Function to open the lightbox and set the video URL
  const openLightbox = (videoUrl) => {
    setLightboxVideoUrl(videoUrl);
    setIsLightboxOpen(true);
  };

  // Function to close the lightbox
  const closeLightbox = () => {
    setLightboxVideoUrl('');
    setIsLightboxOpen(false);
  };

  return (
    <Card
    ref={cardRef}
    sx={{
      margin: 'auto',
      position: 'relative',
      overflow: 'hidden',
      padding: 5,
      backgroundColor: '#ececec',
      transition: 'height 0.5s ease' // Optional: smooth transition for height change
    }}
  >
         <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          filter: 'blur(10px) grayscale(100%)', 
          zIndex: 0,
          inset: '-30px',
          opacity: '15%'
        }}
      />
      {/* ... Avatar and other content ... */}
      <Avatar alt={`${name}'s Picture`} src={imageUrl} sx={{ width: 140, height: 140, margin: 'auto' }} />
      <CardContent
        sx={{
          zIndex: 1,
          position: 'relative',
          color: '#000',
          fontFamily: 'serif',
          textAlign: 'center',
        }}
      >
        <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
          {name}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: 12 }}>
          {`${birthDate}`} - {`${deathDate}`}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }}>
          {`${residence}`}
        </Typography>

        <Chip
          color="secondary"
          sx={{ mt: 2, cursor: 'pointer' }}
          onClick={() => openLightbox(videoUrl)}
          icon={<PlayCircleOutlineIcon />}
          label="My Story"
          variant="outlined"
        />

        <Dialog open={isLightboxOpen} maxWidth={'lg'} onClose={closeLightbox} fullWidth>
          <DialogContent >
            {/* Embed the video player or video link inside the lightbox */}
            <VimeoPlayer url={'https://vimeo.com/308197344'} />
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default PersonDetails;
