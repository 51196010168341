import React from 'react';
import ReactPlayer from 'react-player/vimeo';

const VimeoPlayer = ({ url }) => {
  return (
    <div
      style={{
        position: 'relative',
        paddingTop: '56.25%', // 16:9 aspect ratio (adjust as needed)
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          controls // Enable player controls
        />
      </div>
    </div>
  );
};

export default VimeoPlayer;
