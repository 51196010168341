import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import YouTube from 'react-youtube';
import Vimeo from 'react-vimeo';
import gsap from 'gsap';
import { useNavigate, useParams } from 'react-router-dom';
import { useGesture } from '@use-gesture/react';

const MediaGallery = ({ mediaItems }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const {memorialIDhash, imageIndex } = useParams();

  const imageRefs = React.useRef([]);



// Inside your component
const bind = useGesture({
  onMouseEnter:({}) => {
    console.log('mouse');
  },

  onHover:({})=> {
    console.log('mouseHover');
  },
  onSwipe: ({ direction: [dx] }) => {
    console.log("swipe");
    if (dx > 0) {
      // Swipe right - previous image
      const prevIndex = selectedImageIndex > 0 ? selectedImageIndex - 1 : 0;
      navigate(`/memorials/${memorialIDhash}/gallery/${prevIndex}`);
    } else if (dx < 0) {
      // Swipe left - next image
      const nextIndex = selectedImageIndex < mediaItems.length - 1 ? selectedImageIndex + 1 : selectedImageIndex;
      navigate(`/memorials/${memorialIDhash}/gallery/${nextIndex}`);
    }
  },
}, { swipeVelocity: 0.2 }); // You can adjust the swipe sensitivity here



  useEffect(() => {
    if (imageIndex !== undefined) {
      setSelectedImageIndex(parseInt(imageIndex));
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
      setSelectedImageIndex(null);
    }
  }, [imageIndex]);



  


  console.log(mediaItems, 'mediaItemas')
  // Ref for the container of the items
  const itemsRef = React.useRef([]);

  React.useEffect(() => {
    // GSAP animation
    gsap.fromTo(
      itemsRef.current,
      { autoAlpha: 0, y: 30 },
      {
        autoAlpha: 1,
        y: 0,
        stagger: 0.1, // Stagger the animation of each item
        duration: 0.8, // Duration of the animation in seconds
        ease: 'power3.out', // Easing function
      }
    );
  }, []);

  // Function to handle image click and open the modal
  const handleImageClick = (index) => {
    navigate(`/memorials/${memorialIDhash}/gallery/${index}`);
    setSelectedImageIndex(index);
    setIsModalOpen(true);
  
    // In handleImageClick
gsap.to(imageRefs.current[index], {
  scale: 1.2,
  duration: .5,
  ease: "expo",
  transformOrigin: 'center center',
  yoyo: true,
  repeat: 1
});

  };
  
  const closeModal = () => {
    navigate(`/memorials/${memorialIDhash}/gallery`);
    setIsModalOpen(false);
  
    if (selectedImageIndex !== null) {
      gsap.to(imageRefs.current[selectedImageIndex], {
        scale: 1,
        duration: 0.3,
        ease: 'power3.out'
      });
    }
  
    setSelectedImageIndex(null);
  };
  return (
    <Box sx={{ width: '100%', minHeight: 450, overflowY: 'scroll' }}>
      <ImageList variant="masonry" cols={3} gap={8}>
        {mediaItems.map((item, index) => (
          <ImageListItem
     
            key={index}
            onClick={() => handleImageClick(index)}
            style={{ cursor: 'pointer', overflow: 'hidden'}}
          >
    
    {item.type === 'image' && item.thumb && (
  <div>
    <img
      ref={(el) => (imageRefs.current[index] = el)}
      srcSet={`${item.thumb.sourceUrl}`}
      src={`${item.thumb.sourceUrl}`}
      alt={item.title}
      loading="lazy"
      style={{ width: '100%', height: '100%', objectFit: 'cover' }}
    />
  </div>
)}

            {item.type === 'youtube' && (
              <YouTube videoId={item.src} opts={{ width: '100%', playerVars: { autoplay: 0 } }} />
            )}
            {item.type === 'vimeo' && <Vimeo videoId={item.src} responsive={true} />}
          </ImageListItem>
        ))}
      </ImageList>

  
{isModalOpen && selectedImageIndex !== null && (
  <div
  {...bind()} 
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.7)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999,
    }}
    onClick={closeModal}
  >
    <div
      style={{
        maxWidth: '90%',
        maxHeight: '90%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column', // Adjust for caption
      }}
    >
      {/* Display the selected image in a larger size */}
      {mediaItems[selectedImageIndex].type === 'image' && (
        <img
       
          src={mediaItems[selectedImageIndex].src}
          alt={mediaItems[selectedImageIndex].title}
          style={{
            maxWidth: '100%',
            maxHeight: '75vh',
      
          }}
        />
      )}
      {/* Caption */}
      {mediaItems[selectedImageIndex].caption && (
        <div
        style={{
          background: 'rgba(0, 0, 0, 0.7)',
          color: 'white',
          padding: '8px',
          textAlign: 'center',
        }}
      >
        {mediaItems[selectedImageIndex].caption}
      </div>

      )}
      
    </div>
  </div>
)}
    </Box>
  );
};

export default MediaGallery;
