import { gql } from '@apollo/client';

export const GET_MEMORIAL_BY_CUSTOM_POST_ID = gql`
  query GetMemorialByCustomPostId($customPostId: String!) {
    memorials(where: {customPostId: $customPostId}) {
      edges {
        node {
          id
          memorial {
            firstName
            lastName
            birthday
            deathDate
            city
            state
            obituary
            gallery {
                sourceUrl
                slug
                uri
                mediaDetails {
                  file
                  sizes {
                    sourceUrl
                  }
                }
              }
              narration {
                mediaItemUrl
                mediaDetails {
                  file
                }
              }
            profileImage {
              id
              sourceUrl
              uri
              mediaDetails {
                file
              }
            }
          }
          milestone {
            milestoneDetails {
              date
              title
              description
              milestoneType
              photo {
                sourceUrl
              }
            }
          }
        }
      }
    }
  }`;