import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap'; // Import GSAP
import './Obituary.css'; // Import a CSS file for custom styling

import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Chip,
  Box,
} from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ReactPlayer from 'react-player'; // Import react-player
import PersonDetails from './PersonDetails';

const Obituary = ({ text, person, narration }) => {
  const [isPlayerVisible, setIsPlayerVisible] = useState(false);

  // Create a ref for the content element and react-player element
  const contentRef = useRef(null);
  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const playerButtonRef = useRef(null);


  useEffect(() => {
    // Check if GSAP and the content element ref are available
    if (gsap && contentRef.current) {
      // Initialize the animation
      const tl = gsap.timeline();

      // Hide the content initially
      tl.set(contentRef.current, { opacity: 0 });

      // Animate the content in
      tl.to(contentRef.current, { opacity: 1, duration: 1, ease: 'power2.inOut' });
    }
  }, []);

  const handleListenClick = () => {
    setIsPlayerVisible(true);

    if (playerRef.current) {
      playerRef.current.seekTo(0); // Start from the beginning
      playerRef.current.getInternalPlayer().play(); // Play the audio
    }
    // Use GSAP to fade in and move the react-player element

    gsap.fromTo(
      playerButtonRef.current,
      { opacity: 1, y: 0 }, // Start with opacity 0 and slightly below the bottom
      { 
        opacity: 0,
  
        y: -50, // Move to the original position
        duration: 1, // Animation duration
        ease: 'power2.inOut',
        onComplete: ()=>{
          gsap.set(playerButtonRef.current, {visibility: 'hidden'});

      gsap.fromTo(
        playerContainerRef.current,
        { opacity: 0, y: 50 }, // Start with opacity 0 and slightly below the bottom
        { 
          opacity: 1,
          y: 0, // Move to the original position
          duration: 1, // Animation duration
          ease: 'power2.inOut',
        }
      );
        }
      }
    
    );


 
    
  };

  return (
    <Box ref={contentRef}>
            <PersonDetails person={person}  />

            {narration ?<>
<Box sx={{ textAlign: 'left', mt: 2, zIndex:1, position: 'relative'}} ref={playerButtonRef}>
<Chip
  color="secondary"
  sx={{ mt: 2, cursor: 'pointer', textAlign: 'left' }}
  icon={<PlayCircleOutlineIcon />}
  label="Listen to Obituary"
  variant="outlined"
  onClick={handleListenClick}
/>
</Box>



<Box sx={{opacity: 0, mt: -5}} ref={playerContainerRef} >

<ReactPlayer
// Add a ref to the react-player element
url={narration.mediaItemUrl}
ref={playerRef}
controls // Show audio controls (play, pause, volume, etc.)
width="100%"
height="50px" // Adjust the height as needed
/>

</Box></>
            
          : null}
          
      

      <Typography
        variant="body1"
        color="textSecondary"
        sx={{ mt: 2, textAlign: 'left' }}
      >
        {/* Apply a CSS class for custom styling and use the ref */}
        <div className="obituary-content"  dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
    </Box>
  );
};

export default Obituary;
