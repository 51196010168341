import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';

const client = new ApolloClient({
    link: new HttpLink({
      uri: 'https://tributescan.com/graphql',
      credentials: 'omit', // Include cookies in requests
    }),
    cache: new InMemoryCache(),
  });

export default client;