import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Modal,
  TextField,
} from '@mui/material';
import gsap from 'gsap';

const Memories = ({ memories, person }) => {
  const cardRefs = useRef([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newMemory, setNewMemory] = useState('');
  const [name, setName] = useState('');
  const [relation, setRelation] = useState('');

  useEffect(() => {
    // Animate the cards when the component mounts
    gsap.fromTo(
      cardRefs.current,
      { opacity: 0, y: 20 },
      { opacity: 1, y: 0, stagger: 0.2, duration: 0.5, ease: 'power3.out' }
    );
  }, []);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmitMemory = () => {
    // Handle memory submission here
    // You can send the 'newMemory', 'name', and 'relation' to your backend or handle it as needed
    console.log('Memory submitted:', newMemory);
    console.log('Name:', name);
    console.log('Relation:', relation);
    // After submitting, clear the form and close the modal
    setNewMemory('');
    setName('');
    setRelation('');
    handleModalClose();
  };

  return (
    <div>

<Button variant="outlined" color='secondary' onClick={handleModalOpen}>
        Submit A Memory
      </Button>
      {memories.map((memory, index) => (
        <div
          key={index}
          ref={(el) => (cardRefs.current[index] = el)}
          style={{ opacity: 0, transform: 'translateY(20px)' }}
        >
          <Card sx={{ margin: '20px 0', textAlign: 'left' }}>
            <CardContent>
              <Typography variant="body1">{memory.text}</Typography>
              <Box sx={{ textAlign: 'right' }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: 2,
                    borderTop: '1px solid #ededed',
                    pt: 2,
                    mb: 0,
                    mr: 4,
                    fontSize: 14,
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  {memory.submittedBy}
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: -0.5,
                    textTransform: 'capitalize',
                    fontSize: 12,
                    mr: 4,
                    fontStyle: 'italic',
                  }}
                  color="text.secondary"
                  gutterBottom
                >
                  {memory.relation}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </div>
      ))}
     
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            minWidth: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Submit a Memory
          </Typography>
          <TextField
            multiline
            rows={4}
            variant="outlined"
            fullWidth
            label="Your Memory"
            value={newMemory}
            onChange={(e) => setNewMemory(e.target.value)}
          />
          <TextField
            variant="outlined"
            fullWidth
            label="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mt: 2 }}
          />
          <TextField
            variant="outlined"
            fullWidth
            label={`Relation to ${person.first_name}`}
            value={relation}
            onChange={(e) => setRelation(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleSubmitMemory}
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default Memories;
