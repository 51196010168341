import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainComponent from './MainComponent';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/memorials/:memorialIDhash" element={<MainComponent />} >
        <Route path="gallery/:imageIndex" element={<MainComponent />} />
          </Route>
        <Route path="/memorials/:memorialIDhash/obituary" element={<MainComponent />} />
        <Route path="/memorials/:memorialIDhash/timeline" element={<MainComponent />} />
        <Route path="/memorials/:memorialIDhash/gallery" element={<MainComponent />} />
        <Route path="/memorials/:memorialIDhash/memories" element={<MainComponent />} />
        
        <Route path="/" element={<div>TributeScan.com</div>} />
        {/* Add other routes here if needed */}
      </Routes>
    </Router>
  );
};

export default App;
