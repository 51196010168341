import React, { useEffect, useRef, useState } from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { Typography, Modal, Button } from '@mui/material';
import gsap from 'gsap';

const LifeTimeline = ({ lifeEvents }) => {
  // Ref for the timeline items
  const timelineItemsRef = useRef([]);
  timelineItemsRef.current = [];

  // State to track the selected photo and modal open/close
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // Set initial state for each timeline item
    gsap.set(timelineItemsRef.current, { autoAlpha: 0, y: 100 });

    // GSAP animation for timeline items
    gsap.to(timelineItemsRef.current, {
      duration: 0.5,
      autoAlpha: 1, // Animate to fully opaque
      y: 0, // End at their natural position
      stagger: 0.25,
      ease: 'power1.out',
    });
  }, []);

  // Function to handle clicking on a photo
  const handlePhotoClick = (photoUrl) => {
    setSelectedPhoto(photoUrl);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedPhoto(null);
    setIsModalOpen(false);
  };

  return (
    <div>
      <Timeline position="alternate">
        {lifeEvents ? 
        <>
         {lifeEvents.map((event, index) => (
          <TimelineItem
            key={index}
            ref={(el) => {
              // Add the element to the timelineItemsRef array
              timelineItemsRef.current[index] = el;
            }}
          >
            <TimelineSeparator>
              <TimelineDot variant={index % 2 === 0 ? 'outlined' : 'default'} />
              {lifeEvents.length - 1 !== index && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>{event.date}</Typography>
              <Typography variant="h6" component="h1" sx={{ fontSize: 12 }}>
                {event.title}
              </Typography>
              {event.photo && (
                <img
                  src={event.photo.sourceUrl}
                  alt={event.title}
                  style={{ maxWidth: '100%', height: 'auto', marginTop: '8px', cursor: 'pointer' }}
                  onClick={() => handlePhotoClick(event.photo.sourceUrl)}
                />
              )}
              <Typography sx={{ fontSize: 8, fontStyle: 'italic' }}>{event.description}</Typography>
            </TimelineContent>
          </TimelineItem>
        ))}
        </>
        
      : <Typography>No Timeline Events to Display</Typography>}
       
      </Timeline>
      {/* Modal */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
          {selectedPhoto && (
            <img src={selectedPhoto} alt="Selected Photo" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
          )}
          {/* <Button onClick={handleCloseModal}>Close</Button> */}
        </div>
      </Modal>
    </div>
  );
};

export default LifeTimeline;
