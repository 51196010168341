
  
  import React, { useState, useEffect } from 'react';
  import logo from './logo.svg';
  import LifeTimeline from './Components/LifeTimeline';
  import PersonDetails from './Components/PersonDetails';
  import Obituary from './Components/Obituary';
  import MediaGallery from './Components/MediaGallery';
  import Memories from './Components/Memories';
  import { useQuery } from '@apollo/client';
  import { GET_MEMORIAL_BY_CUSTOM_POST_ID } from './Components/queries';
  import { BottomNavigation, BottomNavigationAction, Container, Box, Paper } from '@mui/material';
  import RestoreIcon from '@mui/icons-material/Restore';
  import FavoriteIcon from '@mui/icons-material/Favorite';
  import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
  import NotesIcon from '@mui/icons-material/Notes';
  import LoadingSpinner from './Components/LoadingSpinner';
  import { useNavigate, useLocation, useParams } from 'react-router-dom';


   import gsap from 'gsap';
  function MainComponent() {
    const [value, setValue] = useState(0);
    let { memorialIDhash } = useParams();
    console.log(memorialIDhash, 'hash')

    const webServer = 'https://tributescan.com'
    const [tabIndex, setTabIndex] = useState(1);

    const { loading, error, data } = useQuery(GET_MEMORIAL_BY_CUSTOM_POST_ID, {
      variables: { customPostId: memorialIDhash },
    });
      // State to track whether loading is complete
      const [isLoadingComplete, setIsLoadingComplete] = useState(false);


      const [scrollPosition, setScrollPosition] = useState(0);
      const shrinkThreshold = 100; // Set a threshold for shrinking
    
      const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
      };


      const navigate = useNavigate();

const handleNavigationChange = (event, newValue) => {
  switch (newValue) {
    case 0:
      navigate("/memorials/" + memorialIDhash + "/obituary");
      break;
    case 1:
      navigate("/memorials/" + memorialIDhash + "/timeline");
      break;
    case 2:
      navigate("/memorials/" + memorialIDhash + "/gallery");
      break;
    case 3:
      navigate("/memorials/" + memorialIDhash + "/memories");
      break;
    default:
      break;
  }
};



      const location = useLocation();
      useEffect(() => {
        const paths = location.pathname.split("/");
        // Assuming the path structure is something like /memorials/:memorialIDhash/gallery/:imageIndex
        if (paths.includes("gallery")) {
          if (paths.length > 4) {
            // This is a sub-route of the gallery, such as viewing a specific image
            setValue(2); // Set to gallery tab
          } else {
            // This is the main gallery route
            setValue(2); // Set to gallery tab
          }
        } else {
          switch (paths.pop()) {
            case "obituary":
              setValue(0);
              break;
            case "timeline":
              setValue(1);
              break;
            case "memories":
              setValue(3);
              break;
            default:
              setValue(0); // Default to the first tab or handle differently
          }
        }
      }, [location]);


      useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


let shouldShrink = scrollPosition;

     


      // Animate the black bar to reveal content when loading is complete
  useEffect(() => {
    gsap.set('.logo', { opacity: 0 });
    if (!loading) {
      // Loading is complete
      setIsLoadingComplete(true);

      // Animate the black bar
      gsap.to('.black-bar', {
        delay: 1,
        height: 80, // Animate the width to 0 to reveal content
        duration: 1, // Animation duration in seconds
        onComplete: () => {

         

          gsap.fromTo(
            '.logo',
            { opacity: 0, y: -20},
            {
              opacity: 1,
              y: 1,
              duration: 1, // Logo fade-in duration
              delay: 0.5, // Delay before the logo fades in
            }
          );
          // Optionally, you can remove or hide the black bar element after the animation is complete
          gsap.set('.black-bar', { display: 'none' });
            // Fade in the logo after the header bar animation is complete
            
        },
      });
    }
  }, [loading]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [value]);




    let firstMemorial;
    let milestones;

    let mediaItems;
    if (loading) return <LoadingSpinner/>;
    if (error) return <p>Error :</p>;
    if (data) {
      console.log(data)
      firstMemorial  = data.memorials.edges[0].node.memorial;
      milestones = data.memorials.edges[0].node.milestone.milestoneDetails;
     mediaItems = data.memorials.edges.map(edge => {
        return edge.node.memorial.gallery.map(galleryItem => {
          let thumbnail;
          if(galleryItem.mediaDetails.sizes[2]) {
            thumbnail = galleryItem.mediaDetails.sizes[0];
          } else {
            thumbnail = galleryItem.mediaDetails.sizes[0]
          }
          return {
            type: "image",
            src: galleryItem.sourceUrl,
            thumb: thumbnail
          };
        });
      }).flat();

      console.log(mediaItems)
      
    };




    const person = {
      name: `${firstMemorial.firstName} ${firstMemorial.lastName}`,
      first_name: firstMemorial.firstName,
      last_name: firstMemorial.lastName,
      birthDate: firstMemorial.birthday, // You might want to format this date
      deathDate: firstMemorial.deathDate, // This date should come from your data, currently it's hardcoded
      residence: `${firstMemorial.city}, ${firstMemorial.state}`, // This should also come from your data if available
      obituary: firstMemorial.obituary,
      narration: firstMemorial.narration,
    imageUrl: firstMemorial.profileImage.sourceUrl };


    

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
      };
    

      const memorySubmissions = [
        {
          title: 'A Caring Mother',
          submittedBy: 'Sarah Smith',
          relation: 'daughter',
          text: 'Our mother was the embodiment of love and compassion. She selflessly cared for us, nurtured our dreams, and filled our home with warmth and kindness. Her love will forever remain in our hearts.'
        },
        {
          title: 'My Beloved Sister',
          submittedBy: 'Emily Johnson',
          relation: 'brother',
          text: 'My sister was my confidant and closest friend. She stood by me through thick and thin, offering unwavering support and love. Her laughter was infectious, and her presence made every moment brighter.'
        },
        {
          title: 'A True Friend',
          submittedBy: 'Michael Brown',
          relation: 'friend',
          text: 'As a friend, he/she was always there, ready to listen, offer advice, and share in both our joys and sorrows. Our friendship was a precious gift, and the memories we created together will forever hold a special place in my heart.'
        },
        // ... other memories
      ];
      
      

  
    // ... your existing code for data fetching and component structure
  
    return (
     <>
        {/* Header Bar */}
        <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 0,
          borderBottom: '1px solid #ccc',
          backgroundColor: '#000',
          m:0,
  
          p: 2
        }}
      >
        {/* Logo */}
        <a href="https://tributescan.com" target='_blank'>
        <img style={{opacity: 0}} className='logo' src={webServer + '/wp-content/uploads/2023/11/LogoHz.png'} alt="Logo" style={{ maxWidth:'200px', height: 'auto' }} />
        </a>
        
      </Box>
  
        {/* Content */}
        <Box sx={{ width: '100%', textAlign: 'center', m:0, p: 0,}}>

        
          <Paper
            className="black-bar"
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              zIndex: 9999, // Ensure it's above other elements
            }}
          ></Paper>
      
    

    <Container  sx={{p:3, mb: 3, minHeight: 900}} maxWidth="lg">
    
          {/* Render content based on the selected BottomNavigation item */}
          {value === 0 && <Obituary text={person.obituary} person={person} narration={person.narration} />}
          {value === 1 && <LifeTimeline lifeEvents={milestones} />}
          {value === 2 && <MediaGallery mediaItems={mediaItems} />}
          {value === 3 && <Memories memories={memorySubmissions} person={person} />}
          </Container>

        </Box>
  
        {/* Bottom Navigation */}
        <BottomNavigation
  showLabels
  value={value}
  onChange={handleNavigationChange}
  sx={{
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    pb: 2,
    backgroundColor: '#000', // Set background color to black
  }}
>
  <BottomNavigationAction 
    label="Obituary" 
    icon={<NotesIcon style={{ color: 'white' }} />} // Set icon color to white
    sx={{
      color: 'white', // Set label text color to white for inactive state
      '&.Mui-selected': {
        color: 'white', // Set label text color to white for active state
      },
    }}
  />
  <BottomNavigationAction 
    label="Timeline" 
    icon={<RestoreIcon style={{ color: 'white' }} />} 
    sx={{
      color: 'white',
      '&.Mui-selected': {
        color: 'white',
      },
    }}
  />
  <BottomNavigationAction 
    label="Gallery" 
    icon={<PhotoLibraryIcon style={{ color: 'white' }} />} 
    sx={{
      color: 'white',
      '&.Mui-selected': {
        color: 'white',
      },
    }}
  />
  <BottomNavigationAction 
    label="Memories" 
    icon={<FavoriteIcon style={{ color: 'white' }} />} 
    sx={{
      color: 'white',
      '&.Mui-selected': {
        color: 'white',
      },
    }}
  />
</BottomNavigation>


      </>
    );
  }
  
  export default MainComponent;
  